import { createContext, useState, useContext } from "react";

const EditRequest = createContext({
  activeRequestId: 0,
  setActiveRequestId: null,
});

export function EditRequestProvider({ children }) {
  const [activeRequestId, setActiveRequestId] = useState(0);

  const value = { activeRequestId, setActiveRequestId };

  return <EditRequest.Provider value={value}>{children}</EditRequest.Provider>;
}

export function useEditRequest() {
  const context = useContext(EditRequest);
  if (!context) {
    throw new Error("useEditRequest must be used within EditRequestProvider");
  }
  return context;
}
