import React, { useEffect } from "react";
import "./App.css";
// import AllRequestLogs from "./components/RequestLogger/AllRequestLogs";
import AllRequestLogs from "./components/RequestLogger/TempAllRequestLogs";
import { LoadingProvider } from "./contexts/LoadingContext";
import { AddCommentProvider } from "./contexts/AddCommentContext";
import { EditRequestProvider } from "./contexts/EditRequestContext";
import uuid from "uuid";

function App() {
  useEffect(() => {
    if (localStorage.getItem("authorId")) {
    } else {
      localStorage.setItem("authorId", uuid.v4());
    }
  }, []);
  return (
    <div className="App">
      <div className="container">
        <LoadingProvider>
          <AddCommentProvider>
            <EditRequestProvider>
              <AllRequestLogs />
            </EditRequestProvider>
          </AddCommentProvider>
        </LoadingProvider>
      </div>
    </div>
  );
}

export default App;
