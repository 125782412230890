import React, { useState, useRef, useEffect } from "react";
import RequestLoggerService from "../../services/RequestLoggerService";
import config from "../../config";
import { InputTags } from "react-bootstrap-tagsinput";
import { useLoading } from "../../contexts/LoadingContext";
// import { Editor } from "@tinymce/tinymce-react";
import { useAddComment } from "../../contexts/AddCommentContext";
import { useEditRequest } from "../../contexts/EditRequestContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function TempSingleRequest(props) {
  const { activeCommentRequestId, setActiveCommentRequestId } = useAddComment();
  const { activeRequestId, setActiveRequestId } = useEditRequest();
  const { setLoading } = useLoading();
  const [activeComment, setActiveComment] = useState(false);
  const [comment, setComment] = useState("");
  // const [activeAddComment, setActiveAddComment] = useState(false);

  const [addRequestButton, setAddRequestButton] = useState(false);
  const [addRequest, setAddRequest] = useState({
    title: props.title,
    description: props.description,
    files: props.files,
    tags: props.tags,
  });
  const [createdTags, setCreatedTags] = useState(props.tags ? props.tags : []);
  // const editorRef = useRef(null);
  const [authorId, setAuthorId] = useState("");
  const [description, setDescription] = useState(props.description);

  useEffect(() => {
    if (localStorage.getItem("authorId")) {
      setAuthorId(localStorage.getItem("authorId"));
    } else {
      setAuthorId("");
    }
  }, []);

  const handleCommentSubmit = () => {
    RequestLoggerService.postComments(props.id, {
      comment,
      authorId: localStorage.getItem("authorId"),
    })
      .then((res) => {
        if (res.data.success && res.data.data) {
          props.commentSubmitted(true);
        }
      })
      .catch((ex) => console.log(ex));
    setComment("");
    // setActiveAddComment(!activeAddComment);
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    if (addRequest.files.length > 0) {
      for (const item of addRequest.files) {
        formData.append("files", item);
      }
    }

    formData.append("authorId", localStorage.getItem("authorId"));
    formData.append("title", addRequest.title);
    // formData.append("description", addRequest.description);
    formData.append("description", description);
    formData.append("comments", addRequest.comments);
    formData.append("tags", createdTags);

    RequestLoggerService.updateRequests(props.id, formData)
      .then((res) => {
        if (res.data.success && res.data.data) {
          console.log("request updated");
          setLoading(false);
          props.setSubmitRequest(true);
          // props.setEditRequestButton(0);
          setActiveRequestId(0);
        }
      })
      .catch((ex) => console.log(ex));
    setAddRequestButton(!addRequestButton);
  };

  if (activeRequestId === props.id) {
    const elementToScroll = document.getElementById(props.id);
    if (elementToScroll) {
      elementToScroll.scrollIntoView();
    }
  }

  return (
    <div>
      {activeRequestId === props.id ? (
        <div className="card" id={props.id}>
          <div className="card-body">
            <section className="">
              <div className="" encType="multipart/form-data">
                <div className="col-sm-12 p-0 my-2">
                  <div className="input-block">
                    <textarea
                      rows="2"
                      type="text"
                      id="title"
                      className="form-control"
                      placeholder="title"
                      value={addRequest.title}
                      onChange={(e) => {
                        setAddRequest({
                          ...addRequest,
                          title: e.target.value,
                        });
                      }}
                    ></textarea>
                  </div>
                </div>

                {/* <div className="col-sm-12 p-0 my-2">
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={`<p>${
                      addRequest.description ? addRequest.description : ""
                    }</p>`}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    value={addRequest.description}
                    onEditorChange={(content, editor) => {
                      setAddRequest({
                        ...addRequest,
                        description: content,
                      });
                    }}
                  />
                </div> */}

                <div className="col-sm-12 p-0 my-2">
                  <CKEditor
                    editor={ClassicEditor}
                    data={`${description ? description : ""}`}
                    onReady={(editor) => {
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>

                <div className="input-group">
                  <InputTags
                    values={createdTags}
                    onTags={(value) => setCreatedTags(value.values)}
                    placeholder="tags"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    data-testid="button-clearAll"
                    onClick={() => {
                      setCreatedTags([]);
                    }}
                  >
                    Delete all
                  </button>
                </div>

                <div className="col-sm-12 p-0 my-2">
                  <div className="input-bloc">
                    <div className="custom-contro">
                      <input
                        type="file"
                        className="form-control"
                        id="files"
                        name="files"
                        multiple
                        onChange={(e) => {
                          setAddRequest({
                            ...addRequest,
                            files: e.target.files,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 p-0">
                  <button
                    className="btn btn-light mt-2 mr-2"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-warning mt-2 mr-2"
                    // onClick={() => setAddRequestButton(false)}
                    // onClick={() => props.setEditRequestButton(0)}
                    onClick={() => setActiveRequestId(0)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="card" style={{ cursor: "pointer" }}>
          <a
            className="card-title h6 text-primary my-0 mx-1 px-3 py-2"
            role="button"
            href="/#"
            onClick={() => {
              // setAddRequestButton(!addRequestButton);
              if (props.authorId === localStorage.getItem("authorId")) {
                setActiveRequestId(props.id);
              }
              // else {
              //   alert("Only author is allowed to edit this post");
              // }
            }}
          >
            {props.title}
          </a>

          <div
            className="card-body pt-2"
            onClick={(e) => {
              // setActiveAddComment(!activeAddComment);
              if (props.id !== activeCommentRequestId) {
                setActiveCommentRequestId(props.id);
              } else {
                setActiveCommentRequestId(0);
              }
            }}
          >
            <p
              className="card-text"
              dangerouslySetInnerHTML={{
                __html: props.description,
              }}
            >
              {/* {props.description} */}
            </p>

            <div>
              <div>
                {props.files &&
                  props.files.length > 0 &&
                  props.files.map((item, index) => {
                    return (
                      <div key={index} className="">
                        <a
                          href={`${config.files}/${item.filename}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.originalname}
                        </a>
                      </div>
                    );
                  })}
              </div>

              <div className="my-2">
                {props.tags.length !== 0 &&
                  props.tags[0] !== "" &&
                  props.tags.map((item, index) => {
                    return (
                      <button
                        className="btn btn-sm text-primary mr-1"
                        key={index}
                        style={{
                          fontSize: "11px",
                          padding: "1px 3px 1px 3px",
                          backgroundColor: "#E0EAF1",
                        }}
                        onClick={() => props.filterByTag(item)}
                      >
                        <span className="">{item}</span>
                      </button>
                    );
                  })}
              </div>

              <div className="row">
                <div className="col">
                  {!activeComment ? (
                    <span
                      className={`btn text-muted p-0 request-link`}
                      onClick={() => setActiveComment(!activeComment)}
                    >
                      <i className="fas fa-comments mr-2"></i>
                      {props.comments.length > 0 && props.comments.length}
                    </span>
                  ) : (
                    <span
                      className={`btn p-0 request-link ${
                        props.comments.length > 0
                          ? "text-primary"
                          : "text-muted"
                      } `}
                      onClick={() => setActiveComment(!activeComment)}
                    >
                      <i className="fas fa-comments mr-2"></i>
                      <span className="">
                        {props.comments.length > 0 && props.comments.length}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>

            {props.comments.length > 0 && activeComment && (
              <div className="d-flex flex-column">
                {props.comments.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={`${authorId === item.authorId && "ml-auto"}`}
                      >
                        {/* {authorId !== item.authorId && (
                          <img
                            src="https://www.w3schools.com/w3css/img_avatar3.png"
                            alt="Avatar"
                            className="avatar"
                            title={item.authorId && item.authorId}
                          />
                        )} */}
                        <span
                          className="my-1 px-2"
                          title={item.authorId && item.authorId}
                        >
                          <small>{item.comment}</small>
                        </span>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}

            {activeCommentRequestId === props.id && (
              <div className="mt-3">
                <div className="row no-gutters">
                  <div className="col col-12">
                    <input
                      type="text"
                      // autoFocus={activeAddComment}
                      className="form-control"
                      placeholder="Write something"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        const key = e.keycode || e.which;
                        if (key === 13) {
                          handleCommentSubmit();
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
