import React, { useState, useEffect } from "react";
import TempSingleRequest from "./TempSingleRequest";
import RequestLoggerService from "../../services/RequestLoggerService";
import Tags from "./Tags";
import "react-bootstrap-tagsinput/dist/index.css";
import { useLoading } from "../../contexts/LoadingContext";
import { useEditRequest } from "../../contexts/EditRequestContext";

export default function TempAllRequestLogs() {
  const { loading, setLoading } = useLoading();
  const { setEditRequestId } = useEditRequest();
  const [requests, setRequests] = useState([]);
  const [addRequest, setAddRequest] = useState({
    title: "",
  });
  const [submitRequest, setSubmitRequest] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagFilter, setTagFilter] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [toggleSearchBar, setToggleSearchBar] = useState(false);

  const [page, setPage] = useState(1);
  const [allRequests, setAllRequests] = useState(false);
  const [searchedRequests, setSearchedRequests] = useState([]);
  const [searchResultMessage, setSearchResultMessage] = useState(
    "Type something to search"
  );
  const [requestsEqualsSearchRequests, setRequestsEqualSearchRequests] =
    useState(false);

  const getRequests = () => {
    RequestLoggerService.filterRequestsByTags({ tagFilter, page })
      .then((res) => {
        if (res.data.data) {
          setRequests(res.data.data);
        }
      })
      .catch((ex) => console.log(ex));
  };

  const getTags = (pageNumber) => {
    const getPage = pageNumber || page;
    RequestLoggerService.filterRequestsByTags({ tagFilter, page: getPage })
      .then((res) => {
        if (res.data.data) {
          if (res.data.maxRequests) {
            setAllRequests(true);
          } else {
            setAllRequests(false);
          }
          setRequestsEqualSearchRequests(true);
          setRequests(res.data.data);
          //
          setSearchedRequests(res.data.data);
          RequestLoggerService.getDistinctTags().then((res) => {
            if (res.data.data) {
              setTags(res.data.data);
              setLoading(false);
              if (getPage > 1) {
                const elementToScroll = document.getElementById(
                  `req#${page * 5 - 1}`
                );
                if (elementToScroll) {
                  elementToScroll.scrollIntoView();
                }
              }
            }
          });
        }
      })
      .catch((ex) => console.log(ex.response));
  };

  const loadData = () => {
    setLoading(true);
    getTags();
  };

  useEffect(() => {
    getRequests();
  }, []);

  useEffect(() => {
    loadData();
  }, [submitRequest, tagFilter]);

  // useEffect(() => {
  //   loadData();
  // }, [page]);

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append("title", addRequest.title);
    formData.append("authorId", localStorage.getItem("authorId"));
    RequestLoggerService.postRequests(formData)
      .then((res) => {
        if (res.data.success) {
          console.log("request added");
          setEditRequestId(res.data.data._id);
          setSubmitRequest(!submitRequest);
        }
      })
      .catch((ex) => console.log(ex));
    setAddRequest({
      title: "",
    });
  };

  const handleAddTag = (tag) => {
    const temp = [...tagFilter];
    const foundTag = temp.indexOf(tag);
    if (foundTag === -1) {
      temp.push(tag);
    } else {
      temp.splice(foundTag, 1);
    }
    setTagFilter(temp);
  };

  const getRequestsByTextSearch = () => {
    RequestLoggerService.getRequestsByTextSearch(searchText)
      .then((res) => {
        if (res.data.success) {
          setSearchedRequests(res.data.data);
        } else {
          setSearchedRequests([]);
          setRequestsEqualSearchRequests(false);
          setSearchResultMessage("No requests found");
        }
      })
      .catch((ex) => {
        console.log(ex.response);
        setSearchedRequests(requests);
        setRequestsEqualSearchRequests(true);
        // setSearchResultMessage("No requests found");
      });
  };

  if (loading) {
    return <div className="loadingspinner"></div>;
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col col-10">
          <div className="d-flex flex-row-reverse">
            <div className={`px-2 ml-2`}>
              <div className="search-container">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getRequestsByTextSearch();
                  }}
                  action="#"
                >
                  {!toggleSearchBar ? (
                    <input
                      className="search expandright"
                      id="searchright"
                      type="search"
                      name="q"
                      placeholder="Type to search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  ) : (
                    <input
                      className="search activeSearch expandright border border-top-0 border-left-0 border-right-0"
                      id="searchright"
                      type="search"
                      name="q"
                      placeholder="Type to search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  )}
                  <label
                    className={`button searchbutton p-0 m-0 ${
                      toggleSearchBar ? " text-primary" : "text-dark"
                    }`}
                    htmlFor="searchright"
                    onClick={() => setToggleSearchBar(!toggleSearchBar)}
                  >
                    <span className="mglass">&#9906;</span>
                  </label>
                </form>
              </div>
            </div>
            {!toggleSearchBar && (
              <div className="w-100">
                <section className="">
                  <div className="" encType="multipart/form-data">
                    <div className="col-sm-12 p-0 my-2">
                      <div className="input-block">
                        <input
                          type="text"
                          id="title"
                          placeholder="Type to add"
                          className="form-control"
                          value={addRequest.title}
                          onChange={(e) => {
                            setAddRequest({
                              ...addRequest,
                              title: e.target.value,
                            });
                          }}
                          onKeyPress={(e) => {
                            const key = e.keycode || e.which;
                            if (key === 13) {
                              handleSubmit();
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>

          {!toggleSearchBar ? (
            <div className="my-3" id="allreq">
              {requests &&
                requests.map((item, index) => {
                  return (
                    <div key={index} className="mb-3" id={`req#${index}`}>
                      <TempSingleRequest
                        authorId={item.authorId}
                        title={item.title}
                        description={item.description || ""}
                        comments={item.comments || ""}
                        files={item.files || []}
                        id={item._id}
                        tags={item.tags ? item.tags : []}
                        commentSubmitted={() => getRequests()}
                        setSubmitRequest={() =>
                          setSubmitRequest(!submitRequest)
                        }
                        filterByTag={(tag) => handleAddTag(tag)}
                        // setEditRequestButton={(id) => setEditRequestId(id)}
                        // editRequestId={editRequestId === item._id}
                      />
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="my-3" id="allreq">
              {searchedRequests &&
                searchedRequests.map((item, index) => {
                  return (
                    <div key={index} className="mb-3" id={`req#${index}`}>
                      <TempSingleRequest
                        authorId={item.authorId}
                        title={item.title}
                        description={item.description || ""}
                        comments={item.comments || ""}
                        files={item.files || []}
                        id={item._id}
                        tags={item.tags ? item.tags : []}
                        commentSubmitted={() => {
                          console.log("submitted");
                          getRequests();
                        }}
                        setSubmitRequest={() =>
                          setSubmitRequest(!submitRequest)
                        }
                        filterByTag={(tag) => handleAddTag(tag)}
                        // setEditRequestButton={(id) => setEditRequestId(id)}
                        // editRequestId={editRequestId === item._id}
                      />
                    </div>
                  );
                })}
            </div>
          )}

          <div className="d-flex justify-content-center my-3">
            <div>
              {/* {!allRequests && !toggleSearchBar && (
                <a
                  id="loadMoreButton"
                  href="/#"
                  role="button"
                  className="text-decoration-none"
                  onClick={(e) => {
                    getTags(page + 1);
                    setPage(page + 1);
                  }}
                >
                  Load more
                </a>
              )} */}

              {!allRequests && requestsEqualsSearchRequests && (
                <a
                  id="loadMoreButton"
                  href="/#"
                  role="button"
                  className="text-decoration-none"
                  onClick={(e) => {
                    getTags(page + 1);
                    setPage(page + 1);
                  }}
                >
                  Load more
                </a>
              )}

              {toggleSearchBar && searchedRequests.length === 0 && (
                <p className="text-dark">{searchResultMessage}</p>
              )}
            </div>
          </div>
        </div>

        <div className="col col-2 mt-2">
          <Tags
            tags={tags}
            filterByTag={(tag) => handleAddTag(tag)}
            activeTags={tagFilter}
          />
        </div>
      </div>
    </>
  );
}
