import axios from "axios";
import config from "../config";

class RequestLoggerService {
  getRequests() {
    return axios.get(`${config.server}/api/requests`);
  }

  postRequests(data) {
    return axios.post(`${config.server}/api/requests`, data);
  }

  updateRequests(requestId, data) {
    return axios.put(`${config.server}/api/requests/${requestId}`, data);
  }

  postComments(requestId, data) {
    return axios.post(
      `${config.server}/api/requests/${requestId}/comments`,
      data
    );
  }

  getDistinctTags() {
    return axios.get(`${config.server}/api/requests/get-distinct-tags`);
  }

  filterRequestsByTags(data) {
    return axios.post(`${config.server}/api/requests/filter-requests`, data);
  }

  getRequestsByTextSearch(searchQuery) {
    return axios.get(`${config.server}/api/requests/search/${searchQuery}`);
  }
}

export default new RequestLoggerService();
