import { createContext, useState, useContext } from "react";

const AddCommentContext = createContext({
  activeCommentRequestId: 0,
  setActiveCommentRequestId: null,
});

export function AddCommentProvider({ children }) {
  const [activeCommentRequestId, setActiveCommentRequestId] = useState(0);

  const value = { activeCommentRequestId, setActiveCommentRequestId };

  return (
    <AddCommentContext.Provider value={value}>
      {children}
    </AddCommentContext.Provider>
  );
}

export function useAddComment() {
  const context = useContext(AddCommentContext);
  if (!context) {
    throw new Error("useAddComment must be used within AddCommentProvider");
  }
  return context;
}
