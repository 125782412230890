import React from "react";

export default function Tags(props) {
  return (
    <>
      {props.tags.length > 0 && (
        <div className="card">
          <div className="card-body">
            {props.tags.map((item, index) => {
              return (
                <div key={index} className="my-1">
                  <p
                    className={`btn text-primary p-1 m-0 ${
                      props.activeTags.includes(item) && "bg-primary text-light"
                    }`}
                    onClick={() => props.filterByTag(item)}
                  >
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
